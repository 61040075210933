import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { PostPageRenderModel } from '../external/blocks-widget/post-page-render-model';
import { fetchMetadata } from './metadata';
import { getPostAndCategories } from './post';

interface FetchInitialDataParams {
  wixCodeApi: IWixAPI;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  wixCodeApi,
  instanceId,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const readingSessionId = `${parseInt(
    `${Math.random() * 10000000}`,
    10,
  )}-${Date.now()}`;

  const { post, categories, appData } = await getPostAndCategories({
    wixCodeApi,
    instanceId,
    flowAPI,
  });

  const metadata = await fetchMetadata({
    wixCodeApi,
    flowAPI,
    postSlug: post.slug,
    postId: post.id,
  });

  return new PostPageRenderModel({
    post,
    tags: post.tags ?? [],
    metadata: {
      ...metadata,
      isLiked: Boolean(post.isLiked),
    },
    readingSessionId,
    categories,
    appData,
    instanceId,
    wixCodeApi,
  });
};
