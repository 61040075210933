import { IWixAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPostFeedMetadataPage } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import { getPostPageMetadata } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import { isEditorOrPreview } from './selectors';

interface FetchMetadataParams {
  flowAPI: ViewerScriptFlowAPI;
  postSlug?: string;
  postId?: string;
  wixCodeApi: IWixAPI;
}

interface MetadataResponse {
  viewCount: number;
  likeCount: number;
  totalComments: number;
  averageRating: number;
  totalRatings: number;
}

export const fetchMetadata = async ({
  flowAPI,
  postSlug,
  postId,
  wixCodeApi,
}: FetchMetadataParams): Promise<MetadataResponse> => {
  if (!postSlug) {
    throw new Error('No post id provided');
  }

  if (isEditorOrPreview(wixCodeApi)) {
    const {
      data: { postFeedMetadataPage },
    } = await flowAPI.httpClient.request(
      getPostFeedMetadataPage({ page: 1, pageSize: 1 }),
    );

    return postFeedMetadataPage?.postMetrics?.[
      postId as string
    ] as MetadataResponse;
  }

  const {
    data: { postPageMetadata },
  } = await flowAPI.httpClient.request(
    getPostPageMetadata({ postId: postSlug }),
  );

  return postPageMetadata?.postMetadata as MetadataResponse;
};
