import { postPageOpened } from '@wix/bi-logger-blog-app-uou/v2';
import {
  TranslatedPostRedirectError,
  BlocksWidgetFacade,
} from '../../external/blocks-widget/blocks-widget.facade';
import {
  categoryIds,
  isPublished,
  paidPlanIds,
  paidPlansHasAccess,
} from '../../external/common/bi-events/event-helpers';
import { NormalizedPost } from '../../external/common/types';
import { AppData } from '../../viewer.app';
import blocksModel from './model';
import { POST_PAGE_SELECTORS } from './post-page-constants';

export default blocksModel.createController(
  ({ appData: _appData, flowAPI, controllerConfig: { wixCodeApi }, $w }) => {
    const appData = _appData as AppData;
    const facade = new BlocksWidgetFacade(flowAPI, wixCodeApi);

    return {
      pageReady: async () => {
        const ui = {
          seeMorePostsButton: $w(
            `#${POST_PAGE_SELECTORS.SEE_MORE_POSTS_BUTTON}`,
          ) as $w.Button,
        };
        const feedPageUrl = await facade.getUrlToFeedPage();

        $w('#postPageRoot')?.customClassList?.add('post-page-root');

        try {
          await appData.subjects.postPageRenderModel.subscribe(
            async ({ post, readingSessionId, seoTags }) => {
              facade.assertPostIsLoadedInCorrectLanguage(post);

              flowAPI.bi?.report(
                postPageOpened({
                  reading_session_id: readingSessionId,
                  // @ts-expect-error
                  referrer: wixCodeApi.window.referrer,
                  post_stable_id: post.id,
                  paid_plan_has_access: paidPlansHasAccess(post) || undefined,
                  paid_plan_id: paidPlanIds(post),
                  category_ids: categoryIds(post),
                  is_published: Boolean(isPublished(post)),
                }),
              );

              wixCodeApi.seo.renderSEOTags(seoTags);
            },
          );
        } catch (error) {
          if (
            !flowAPI.environment.isViewer ||
            error instanceof TranslatedPostRedirectError
          ) {
            return;
          }

          wixCodeApi.seo.setSeoStatusCode(404);
          ui.seeMorePostsButton.link = feedPageUrl;
          ui.seeMorePostsButton.target = '_self';
        }
      },

      exports: {
        ...blocksModel.getExports(),
        getPost: async (): Promise<NormalizedPost> =>
          appData.fetchPublicPostPageRenderModel(),
      },
    };
  },
);
